import React from 'react';

const Footer = () => {
      return (
  
        <footer className="w3-container w3-padding-32 w3-light-grey w3-center w3-xlarge">
          <div className="w3-section">
            <i className="fa fa-facebook-official w3-hover-opacity" />
            <i className="fa fa-instagram w3-hover-opacity" />
            <i className="fa fa-snapchat w3-hover-opacity" />
            <i className="fa fa-pinterest-p w3-hover-opacity" />
            <i className="fa fa-twitter w3-hover-opacity" />
            <i className="fa fa-linkedin w3-hover-opacity" />
          </div>
          <p className="w3-medium">
            Sheltie App, Copyright 2020 Cameron Hammel
          </p>
          <p>
          <a href="https://sheltie.herokuapp.com/contact">
            Contact us
          </a>
          </p>
          <p>
          <a href="https://sheltie.herokuapp.com/privacypolicy">
            Privacy Policy
          </a>
          </p>
        </footer>
      );
    };

  export default Footer;