import React, { useState, useEffect } from 'react';

const List = () => {
  const [list, setList] = useState([]);
  
  useEffect(()=> {
    getList();
  }, [])

  const getList = () => {
    const requestOptions = {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email: 'camhammel@gmail.com'})
    }

    fetch('/getfavourites', requestOptions)
    .then(res => res.json())
    .then(new_list => setList(new_list))
    .catch((err) => {
        console.log(err);
    })
  }

  return (
    <div className="App">
      <div className='web-container'>
        <h1>List of Items</h1>
          {list.length > 0 ? (
            <div>
              {list.map((item) => {
                return(
                  <div>
                    {item}
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <h2>No List Items Found</h2>
            </div>
          )
        }
      </div>
    </div>
  );
}

export default List;