import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import '../index.css';
import Home from './pages/Home';
import List from './pages/List';
import Header from './components/Header';

class App extends Component {
  render() {
    const App = () => (
      <div>
        <Header/>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route exact path='/web' element={<List/>}/>
        </Routes>
      </div>
    )
    return (
      <App/>
    );
  }
}

export default App;