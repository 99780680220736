type Props = {
  src: string;
  aosOption?: string;
};

const MobileScreenshot = (props: Props) => {
  return (
    <div className="mobile-screenshot">
      <img
        src={props.src}
        alt="mobile screenshot"
        data-aos={props.aosOption}
        data-aos-delay="150"
      />
    </div>
  );
};

export default MobileScreenshot;
