import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = (props) => {

    const [selected, setSelected] = React.useState('/');
    let navigate = useNavigate();

    useEffect(()=>{
        if (window.location.pathname.includes('/web'))
            setSelected('web');
        if (window.location.pathname === ('/'))
            setSelected('home');

    }, [])

    if (selected === 'home') {
        return (
            <div className="Navbar" style={{backgroundColor: '#0077ff'}}>
                <h1 className="App-header" style={{fontFamily: 'Yellowtail', fontSize: 80, color: '#fff', backgroundColor: 'unset'}}>Sheltie</h1>
            </div>
        )
    }
    return (
        <div className="Navbar" style={{backgroundColor: '#0077ff'}}>
            <h1 onClick={()=>{navigate('/')}} className="App-header" style={{cursor: 'pointer', marginLeft: 24, alignItems: 'flex-start', textAlign: 'start', fontFamily: 'Yellowtail', fontSize: 48, color: '#fff', backgroundColor: 'unset'}}>Sheltie</h1>
        </div>
    )
}

export default Header;