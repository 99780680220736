import React, { useEffect } from 'react';
import AOS from 'aos';
// import { Link } from 'react-router-dom';
import '../../index.css';
import MobileScreenshot from '../components/MobileScreenshot.tsx';
import Footer from '../components/Footer';
import AppStoreIcon from '../../Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'

import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div className="App">
      <h3>Now available on iOS and Android!</h3>
      <div>
        <p>
          <a href="https://apps.apple.com/us/app/sheltie/id1528057120?mt=8" 
              style={{display:'inline-block', overflow:'hidden', backgroundRepeat: 'no-repeat', width: '135px', height:'40px'}}a>
                <img src={AppStoreIcon} alt='Download on the App Store'/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=app.sheltie&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" style={{marginBottom: '-10px'}}
                  height={60}
                  width={155}
              />
          </a>
        </p>
      </div>
      <div className="screenshot-container">
        <MobileScreenshot src="../../../details-mockup.png" aosOption="zoom-out-right" />
        <MobileScreenshot src="../../../maps-mockup.png" aosOption="zoom-out-left" />
      </div>
      {/* <Link to='/web'>Web Client</Link> */}
      <div className="App-footer">
        <Footer/>
      </div>
    </div>
  );
}
export default Home;